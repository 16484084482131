import React from 'react';
import logo from './assets/img/logo.png';
import Hero from './components/Hero/Hero';
import Header from './components/Header/Header';
import './App.css';

function App() {
  return (
    <div className="App">
      {/* <Header/> */}
      <div className="main">
        <img src={logo} className="App-logo" alt="logo" />
      </div>
      <code>contact@ferret.software</code>
      {/* <Hero/> */}
    </div>
  );
}

export default App;
